import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { TFunction } from 'i18next';
import { IGroup, IUsers } from 'interfaces';
import { RootState } from 'store';

export const defaultGroups = ['user', 'full_administrator'];

const fetchGroupsAndUsers = createAsyncThunk('dataUsers/fetchAll', async (payload: {room_id: string}, thunkApi) => {
  try {    
    const usersRespone = await api.getUsers(payload.room_id);
    const groupsResponce = await api.getGroups(payload.room_id);

    const rootState = thunkApi.getState() as RootState;
    const { dataRoom } = rootState.dataRoom;

    const users = usersRespone.data.map((el) => ({
      ...el,
      key: el.id,
      emailOrRole: el.email,
      type: 'user',
      isOwner: dataRoom?.owner_id === el.id
    }));
    const groups = groupsResponce.data.map((el) => ({
      ...el,
      id: el.group_id!,
      name: el.group_name!,
      key: el.group_id,
      first_name: el.group_name,
      emailOrRole: el.role?.name,
      type: 'group'
    }));

    const dataUserTable = groups.map((group) => {
      const usersOfThisGroup = users.filter((user: IUsers) => user.group.includes(group?.id));
      return {
        ...group,
        first_name: `${group?.first_name} (${usersOfThisGroup.length})`,
        children: usersOfThisGroup.map((user: IUsers) => ({...user, key: `${user.key} ${group.key}`})),
      }
    });

    return {
      users,
      groups,
      dataUserTable: [...dataUserTable, ...users.filter((user:IUsers) => user.group.length === 0)]
    };
  } catch (Err) {
    return thunkApi.rejectWithValue('Failed to download Groups/Users');
  }
});

export const fetchOnlyGroups = createAsyncThunk('dataUsers/fetchGroups', async (payload: string, thunkApi) => {
  try {
    const groups = await api.getGroups(payload);
    const preparedGroups: IGroup[] = groups.data.map((el) => ({
      ...el,
      id: el.group_id!,
      name: el.group_name!,
      key: el.group_id,
      first_name: el.group_name,
      emailOrRole: el.role?.name,
      type: 'group'
    }));
    
    return preparedGroups;
  } catch (Err) {
    return thunkApi.rejectWithValue('Failed to download Groups/Users');
  }
});

export const fetchUserLogs = createAsyncThunk('dataUsers/fetchUserlogs', async (props: {user_id: string, room_id: string, locale: string}, thunkApi) => {
  try {
    const logs = await api.getUserLogs(props.user_id, props.room_id, props.locale);    
    return {
      userLogs: logs.data
    }
  } catch (e) {
    return thunkApi.rejectWithValue("Failed to download user's logs");
  }
});

export const fetchPermissions = createAsyncThunk('dataUsers/fetchPermissions', async (props: {t: TFunction}, thunkApi) => {
  try {
    const permissions = await api.getPermissions('room');       
    return permissions.data.map(permission => ({
      ...permission,
      name: props.t(`Users.modals.permissions.titles.${permission.type}`),
      description: props.t(`Users.modals.permissions.descriptions.${permission.type}`)
    }))
  } catch (e) {
    return thunkApi.rejectWithValue("Failed fetching permissions");
  }
});

export const setNewRole = createAsyncThunk('setNewRole/Roles',
  async (props: {user: IUsers, group_id: string, room_id: string, details: any, t: TFunction}, thunkApi) => {
  try {
    await api.addUserToGroup({
      email: props.user.email,
      group_id: props.group_id,
      room_id: props.room_id,
      details: props.details,
    })
    return {user: props.user, group_id: props.group_id, t: props.t}
  } catch (e) {
    return thunkApi.rejectWithValue("Failed setting new role");
  }
});

export const usersTableWithNewUsersRole = (
  data: any,
  users: IUsers[],
  currentUser: IUsers,
  group_id: String,
  t: TFunction,
) => {
  const resultdata = data.map((elem: any) => {
    const targetUser = users.find((user) => user.email === currentUser?.email);
    const newSelectedUser = { ...currentUser!, group: [group_id] };
    const nameOfGroup = defaultGroups.includes(elem?.name)
      ? t(`Users.table.${elem?.name}`)
      : elem?.name;

    return elem.id === group_id
      ? {
          ...elem,
          first_name: ` ${nameOfGroup} (${elem.children.length + 1})`,
          children: [
            ...elem.children,
            { 
              ...targetUser,
              group: newSelectedUser?.group,
              key: `${targetUser?.key} ${group_id}`
            },
          ],
        }
      : (
        elem.id === targetUser?.group[0]
        ? {
          ...elem,
          first_name: ` ${nameOfGroup} (${elem.children.length - 1})`,
          children: elem.children.filter((user: any) => user.email !== targetUser!.email)
        }
        : elem
      )
  })
  .filter((elem: any) => elem.email !== currentUser?.email);      
  return resultdata
}

export default fetchGroupsAndUsers;
