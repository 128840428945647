import { Button, Divider, InputNumber, Select, Space } from 'antd';
import React from 'react';
import { VariableSizeList } from 'react-window';
import {
  CloseCircleOutlined,
  DownloadOutlined,
  LeftOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  RightOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
} from '@ant-design/icons';
import classes from './Toolbar.module.scss';
import { useTranslation } from 'react-i18next';

type ToolbarType = {
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  windowRef: React.RefObject<VariableSizeList | null>;
  toolbarRef: React.RefCallback<Element>;
  pagesCount: number;
  onClose?: () => void;
  isModal?: boolean;
  currentPage?: number;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  downloadDocument?: () => void;
};

const getOnRangeNum = (number: number, range: number) => {
  if (number > range) return range;
  if (number < 1) return 1;
  return number;
};

const Toolbar: React.FC<ToolbarType> = ({ windowRef, scale, setScale, toolbarRef, pagesCount, onClose, isModal, currentPage = 1, setCurrentPage, downloadDocument}) => {
  const { t } = useTranslation();

  const scrollToItem = (value: number | null) => {
    const result = getOnRangeNum(value!, pagesCount);
    setCurrentPage && setCurrentPage(result);
    windowRef.current && windowRef.current.scrollToItem(result - 1, 'start');
  };
  const onZoomUp = () => setScale((prev) => ( prev <= 4.9 ) ? ( prev + 0.1 ) : prev);
  const onZoomDown = () => setScale((prev) => ( prev >= 0.2 ) ? ( prev - 0.1 ) : prev);
  const onChangeZoom = (_: any, item: {[name:string]: any}) => setScale(+item.value / 100);
  const onIncrementPage = () => scrollToItem(currentPage + 1);
  const onDecrementPage = () => scrollToItem(currentPage - 1);
  const onIncrementSlidePage = () => scrollToItem(currentPage + 10);
  const onDecrementSlidePage = () => scrollToItem(currentPage - 10);

  const sizes = [
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: '100%' },
    { value: 125, label: '125%' },
    { value: 150, label: '150%' },
    { value: 200, label: '200%' },
    { value: 300, label: '300%' },
  ];

  return (
    <div ref={toolbarRef} className={classes.toolbar}>
      <Divider type='vertical' className={classes.divider} />
      <Space>
        <VerticalRightOutlined onClick={onDecrementSlidePage} />
        <LeftOutlined onClick={onDecrementPage} />
        <RightOutlined onClick={onIncrementPage} />
        <VerticalLeftOutlined onClick={onIncrementSlidePage} />
        <InputNumber
          className={classes.pageCount}
          value={currentPage}
          min={1}
          max={pagesCount}
          onChange={scrollToItem}
        />
        <b>of {pagesCount}</b>
      </Space>
      <Divider type='vertical' className={classes.divider} />
      <Space>
        <PlusCircleOutlined onClick={onZoomUp} />
        <MinusCircleOutlined onClick={onZoomDown} />

        <Select
          options={sizes}
          style={{ width: 90 }}
          dropdownStyle={{ zIndex: 99999 }}
          value={{
            value: Math.round(scale*100),
            label: `${Math.round(scale*100)}%`
          }}
          onChange={(_, o) => onChangeZoom(_, o)}
        />

      </Space>
      <Divider type='vertical' className={classes.divider} />

      {downloadDocument && <>
        <Button onClick={downloadDocument} className={classes.downloadBtn} icon={<DownloadOutlined />}>
          {t('templink.download')}
        </Button>
        <Divider type='vertical' className={classes.divider} />
      </>}
      {isModal && <CloseCircleOutlined className={classes.close} onClick={onClose} />}
    </div>
  );
};

export default Toolbar;
