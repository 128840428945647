import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { Blob } from 'buffer';
import { jwtDecode } from 'jwt-decode';
import { deleteCookie, getCookie, setCookie } from 'helpers/cookies';
import { IDocumentInfo, ILocale } from 'interfaces';
import auth from './auth';
import blobErrorToObject from 'helpers/blobErrorToObject';


const converterTemplate = axios.create({
  // @ts-ignore
  baseURL: window.REACT_APP_CONVERTER_API !== 'REPLACE_REACT_APP_CONVERTER_API' ? window.REACT_APP_CONVERTER_API : process.env.REACT_APP_CONVERTER_API || 'http://localhost:8010/api',
  withCredentials: true,
});

converterTemplate.interceptors.request.use(async(config: InternalAxiosRequestConfig) => {
  let accessToken = getCookie('athena-token');
  let decodeInfo: any;
  if (accessToken) {
    try {
      decodeInfo = jwtDecode(accessToken);
    } catch (error) {
      Promise.reject('Error decoding token');
    }
    const expTime = decodeInfo?.exp && decodeInfo.exp * 1000;
    const curTime = new Date().getTime();

    if (expTime && expTime - curTime <= 50000) {
      const refresh_token = getCookie('refresh_token');      
      if (refresh_token) {
        try {
          const freshTokens = await auth.refreshTokens({ refresh_token });             
          setCookie('athena-token', freshTokens.data.access_token, 1);
          freshTokens.data.refresh_token && setCookie('refresh_token', freshTokens.data.refresh_token, 1);
          accessToken = freshTokens.data.access_token;
        } catch (error) {
          Promise.reject('Error refreshing tokens');
          deleteCookie('athena-token');
          deleteCookie('refresh_token');
          window.location.href = `/login`;
        };
      };
    };

    config.headers!.Authorization = `Bearer ${accessToken}`
  } else {
    setCookie('athena-token', '', 1);
  }
  return config;
});

converterTemplate.interceptors.response.use(( response: AxiosResponse ) => {
  return response
}, async (error: AxiosError<any>) => {
  if (error?.response?.data.constructor.name === 'Blob'){
    error.response.data = await blobErrorToObject(error?.response?.data)
  };
  return Promise.reject(error)
});

const convertDocument = (payload: IDocumentInfo & ILocale) => converterTemplate.post<Blob>('/converter/pdf/', payload, { responseType: 'blob' });

const getConfidentReportPDF = (room_id: string, locale: string, type?: string) => converterTemplate.post<Blob>(`/converter/confident/report/pdf/?locale=${locale}`,
  { room_id, type }, { responseType: 'blob' }
);

const convert = {
  convertDocument,
  getConfidentReportPDF
};

export default convert;
