import React from 'react'
import { Button, FormInstance, Modal } from 'antd';
import { TFunction } from 'i18next';
import classes from './LicenseModal.module.scss';
import { useTranslation } from 'react-i18next';
import { privacy } from './localesOfPrivacy';

type PrivacyAndCookieProps = {
  isPrivacyAndCookielOpen?: boolean;
  setIsPrivacyAndCookieOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  form: FormInstance<any>;
};

export default function PrivacyAndCookie(
  {
    isPrivacyAndCookielOpen,
    setIsPrivacyAndCookieOpen,
    t,
    form,
  }: PrivacyAndCookieProps
) {
  const [isAvailableLicenseButton, setIsAvailableLicenseButton] = React.useState<boolean>(false);
  const scrollElement = React.useRef<HTMLDivElement | null>(null);
  const isAlreadyAccept = form.getFieldValue(['is_accepted_privacy_cookie']);

  const { i18n } = useTranslation();  

  const scrollHandler = React.useCallback((e: Event) => {
    const borders = 2;
    const scrollHeight = scrollElement.current?.scrollHeight || 1;
    const scrollTop = scrollElement.current?.scrollTop || 0;
    const clientHeight = scrollElement.current?.clientHeight || 1;

    if ((scrollHeight - scrollTop - borders) <= clientHeight) {
      setIsAvailableLicenseButton(true);
    } else setIsAvailableLicenseButton(false);
  }, []);

  React.useEffect(() => {    
    let localRef: HTMLDivElement | null = null;
    if (scrollElement.current) localRef = scrollElement.current;
    localRef?.addEventListener('scroll', scrollHandler);
    if ((localRef?.scrollHeight || 2) <= (localRef?.clientHeight || 1)) setIsAvailableLicenseButton(true);
    return () => {
      localRef?.removeEventListener('scroll', scrollHandler);
    };
  }, [isPrivacyAndCookielOpen, scrollElement.current]);

  const footerWithAcceptButton = (
    <Button
      disabled={!isAvailableLicenseButton}
      type='primary'
      onClick={() => {
        form.setFieldsValue({ is_accepted_privacy_cookie: true });
        form.validateFields(['is_accepted_privacy_cookie']);
        setIsPrivacyAndCookieOpen(false);
      }}
    >
      {t('Confirm.acceptPolicy')}
    </Button>
  )

  return (
    <Modal
      className={classes.modalLicense}
      width={'90%'}
      onCancel={() => setIsPrivacyAndCookieOpen(false)}
      title={t('personalInfo.privacyAndCookieTitle')}
      open={isPrivacyAndCookielOpen}
      centered={true}
      footer={isAlreadyAccept ? null : footerWithAcceptButton}
    >
      <div ref={scrollElement} className={classes.scrollElem}>
        {privacy[String(i18n.language) as keyof typeof privacy]}
      </div>
    </Modal>
  )
};
