import React from 'react'
import classes from '../ConfigureRoles.module.scss';
import { useAppSelector } from 'store/hook';
import { Button, Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import RowLoader from 'components/Sceletons/RowLoader';
import { IDataUserTable, IPermission } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { defaultGroups } from 'store/reducers/usersGroupCreator';

type GroupsListType = {
  configurableRole: IDataUserTable | null;
  setConfigurableRole: React.Dispatch<React.SetStateAction<IDataUserTable | null>>;
};

export default function GroupsList({configurableRole, setConfigurableRole}: GroupsListType) {
  const isFirstRenderState = React.useRef(true); 
  const skeletonRows = [...new Array(2)].map((_, index) => (<RowLoader key={index} height={35} marginBottom={'12px'} width={230} padding={'0 0 0 20px'} />));
  const { dataUserTable, isGroupLoading } = useAppSelector((state) => state.dataUsers);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (dataUserTable[0] && isFirstRenderState.current) {
      setConfigurableRole(dataUserTable[0] as IDataUserTable);
      isFirstRenderState.current = false;
    }
  }, [dataUserTable]);

  const treeData = dataUserTable.map(group => ({
    title: defaultGroups.includes(group.name!)
      ? t(`Users.table.${group.name}`)
      : group.name,
    key: group.id,
    children: group.children ? group.children?.map(user => ({
      title: `${user.first_name} ${user.last_name}`,
      key: user.id,
      selectable: false,
    })) : null
  }));

  const selectGroup = ( selectedKeys: React.Key[],  _: any ) => {
    const targetGroup = dataUserTable.find(group => group.id === selectedKeys[0]);
    setConfigurableRole(targetGroup as IDataUserTable);
  };

  const creatingNewRole = () => {
    setConfigurableRole({
      id: 'new',
      name: '',
      role: {
        id: 'new',
        name: 'new',
        permissions: []
      }
    })
  }

  return (
    <div className={classes.groupsSection}>
      {isGroupLoading
        ? skeletonRows
        : <Tree
          treeData={treeData as DataNode[]}
          onSelect={selectGroup}
          defaultSelectedKeys={configurableRole ? [configurableRole.id] : []}
          selectedKeys={configurableRole?.id ? [configurableRole?.id] : []}
        />
      }
      <Button
        onClick={creatingNewRole}
        className={classes.addRole}
        type='dashed'
      >
        {t('Users.modals.configureRoles.newRole')}
      </Button>
    </div>
  );
}
