import { Table } from 'antd'
import { TFunction } from 'i18next';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hook';
import classes from '../FileDetailsModal.module.scss';
import { IColumn, ISimpleGroupForAprove, ISimpleUserForAprove } from 'interfaces';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass } from '@fortawesome/free-solid-svg-icons';
import { defaultGroups } from 'store/reducers/usersGroupCreator';

interface IUserAgreement extends ISimpleUserForAprove {
  user_group: string;
  key: string
}

const getLocaleColumns = (t: TFunction) => [
  {
    title: t('Documents.details.agreement.user'),
    dataIndex: 'user_name',
    key: 'user_name',
    width: '30%',
  },
  {
    title: t('Documents.details.agreement.group'),
    dataIndex: 'user_group',
    key: 'user_group',
    width: '25%',
  },
  {
    title: t('Documents.details.agreement.email'),
    dataIndex: 'user_email',
    key: 'user_email',
    width: '25%',
  },
  {
    title: t('Documents.details.agreement.state'),
    dataIndex: 'agreement_state',
    key: 'agreement_state',
    width: '20%',
    className: classes.textToCenter,
  },
];

const iconCases = {
  null: <FontAwesomeIcon className={classes.glassWaiting} icon={faHourglass} />,
  false: <CloseOutlined className={classes.closeRed} />,
  true: <CheckOutlined className={classes.checkGreen} />
} 

export default function AgreementUsers() {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);

  const { isArgeementsLoading, agreements } = useAppSelector((store) => store.agreements);
  const { users, isGroupLoading } = useAppSelector((store) => store.dataUsers);

  const { t } = useTranslation();
  
  const usersWithGroups = agreements?.reduce((acc: IUserAgreement[], currentGroup: ISimpleGroupForAprove) => {
    if (currentGroup.users) {
      const usersWithGroupAndFullName = currentGroup.users.map(user => ({
        ...user,
        user_group: currentGroup.group_name,
        user_name: `${user.user_name} ${users.find(curr => curr.email === user.user_email)?.last_name}`,
        key: user.user_email
      }))
      acc = [...acc, ...usersWithGroupAndFullName]
    }
    return acc
  }, [])

  React.useEffect(()=> {
    const tableColumns = getLocaleColumns(t);
    const customColumns = tableColumns.map((col: IColumn) => ({
      ...col, 
      render: (value: string, info: ISimpleUserForAprove) => renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
  },[isGroupLoading, isArgeementsLoading]);

  const renderCell = (props: any) => {
    const { value, info, col } = props;
    if(col.key === 'agreement_state'){ 
      const iconKey = `${value}` as keyof typeof iconCases;      
      return iconCases[iconKey];
    }

    if(col.key === 'user_group'){       
      return defaultGroups.includes(value)
        ? t(`Users.table.${value}`)
        : value
    }
   
    return value;
  };

  return (
    <>
      {agreements[0] && <Table
        columns={modifiedColumns}
        dataSource={usersWithGroups}
        pagination={false}
        size='small'
        loading={isArgeementsLoading || isGroupLoading}
      />}
    </>
  )
}
