import React from 'react'
import { Button, FormInstance, Modal } from 'antd';
import { TFunction } from 'i18next';
import classes from './LicenseModal.module.scss';
import { license } from './localesOfLicense';
import { useTranslation } from 'react-i18next';

type LicenseModalProps = {
  isLicenseModalOpen?: boolean;
  setIsLicenseModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  form: FormInstance<any>;
};

export default function LicenseModal(
  {
    isLicenseModalOpen,
    setIsLicenseModalOpen,
    t,
    form,
  }: LicenseModalProps
) {
  const [isAvailableLicenseButton, setIsAvailableLicenseButton] = React.useState<boolean>(false);
  const scrollElement = React.useRef<HTMLDivElement | null>(null);
  const isAlreadyAccept = form.getFieldValue(['is_accepted_agreement']);

  const { i18n } = useTranslation();  

  const scrollHandler = React.useCallback((e: Event) => {
    const borders = 2;
    const scrollHeight = scrollElement.current?.scrollHeight || 1;
    const scrollTop = scrollElement.current?.scrollTop || 0;
    const clientHeight = scrollElement.current?.clientHeight || 1;

    if ((scrollHeight - scrollTop - borders) <= clientHeight) {
      setIsAvailableLicenseButton(true);
    } else setIsAvailableLicenseButton(false);
  }, []);

  React.useEffect(() => {    
    let localRef: HTMLDivElement | null = null;
    if (scrollElement.current) localRef = scrollElement.current;
    localRef?.addEventListener('scroll', scrollHandler);
    if ((localRef?.scrollHeight || 2) <= (localRef?.clientHeight || 1)) setIsAvailableLicenseButton(true);
    return () => {
      localRef?.removeEventListener('scroll', scrollHandler);
    };
  }, [isLicenseModalOpen, scrollElement.current]);

  const footerWithAcceptButton = (
    <Button
      disabled={!isAvailableLicenseButton}
      type='primary'
      onClick={() => {
        form.setFieldsValue({ is_accepted_agreement: true });
        form.validateFields(['is_accepted_agreement']);
        setIsLicenseModalOpen(false);
      }}
    >
      {t('Confirm.acceptButton')}
    </Button>
  )

  return (
    <Modal
      className={classes.modalLicense}
      width={'90%'}
      onCancel={() => setIsLicenseModalOpen(false)}
      title={t('personalInfo.licenseTitle')}
      open={isLicenseModalOpen}
      centered={true}
      footer={isAlreadyAccept ? null : footerWithAcceptButton}
    >
      <div ref={scrollElement} className={classes.scrollElem}>
        {license[String(i18n.language) as keyof typeof license]}
      </div>
    </Modal>
  )
}

