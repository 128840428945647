import { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Login from 'routes/Login/Login';
import SignUp from 'routes/SignUp/SignUp';
import Main from 'routes/Main/Main';
import Profile from 'routes/Profile/Profile';
import Documents from 'routes/Documents/Documents';
import Sides from 'routes/Sides/Sides';
import Settings from 'routes/Settings/Settings';
import Logs from 'routes/Logs/Logs'
import NDASection from 'routes/NDASection/NDASection';
import MainPageEmpty from 'routes/MainPageEmpty/MainPageEmpty';
import Chat from 'routes/Chat/Chat';
import Organization from 'routes/Organization/Organization';
import Rooms from 'routes/Rooms/Rooms';
import Confirm from 'routes/Confirm/Confirm';
import Report from 'routes/Report/Report';
import ResetPassword from 'routes/ResetPassword/ResetPassword';
import Reset from 'routes/ResetPassword/Reset';
import TempLink from 'routes/TempLink/TempLink';
import Mobile from 'routes/Mobile/Mobile';
import { getCookie } from 'helpers/cookies';
import OrganizationBook from 'routes/OrganizationBook/OrganizationBook';
import TFAuth from 'routes/TFAuth/TFAuth';
import Billing from 'routes/Billing/Billing'
import CacheBuster from 'react-cache-buster';
import packageFile from '../package.json'
import './scss/app.scss';
import WrongAddress from 'routes/WrongAddress/WrongAddress';

export default function App() {
  const navigate = useNavigate();
  const isProduction = true

  useEffect(() => {
    const isMobile = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)
    if (isMobile) navigate('/unsupported')
  }, []);

  return (
    <CacheBuster
      currentVersion={packageFile.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      metaFileDirectory={'.'}
    >
      <Routes>
        
        <Route path='/login' element={<Authorization><Login /></Authorization>}/>
        <Route path='/signup' element={<Authorization><SignUp /></Authorization>}/>
        <Route path='/reset' element={<Authorization><Reset /></Authorization>}/>
        <Route path='/users/restore-password/:email' element={<Authorization><ResetPassword /></Authorization>}/>
        <Route path='/invite/:hash' element={<Authorization><SignUp /></Authorization>}/>
        <Route path='/users/register/accept/:user_id' element={<Authorization><Confirm /></Authorization>}/>
        <Route path='/temp_link/:document_token' element={<TempLink />}/>
        <Route path='/two_factor_authentication/:password' element={<TFAuth />}/>
        <Route path='/organization/book/:encoded_jwt' element={<OrganizationBook />} />
        <Route path='/unsupported' element={<Mobile />} />
        <Route path='/' element={<Protected><Main /></Protected>}>
          <Route path='/billing' element={<Protected><Billing /></Protected>} />
          <Route path='/rooms' element={<Protected><Rooms /></Protected>} />
          <Route path='/report' element={<Protected><Report /></Protected>} />
          <Route path='/profile' element={<Protected><Profile /></Protected>} />
          <Route path='/noAvailablesRooms' element={<Protected><MainPageEmpty /></Protected>} />
          <Route path='/organization' element={<Protected><Organization/></Protected>} />
          <Route path='/room/:room'>
            <Route index path='documents' element={<Protected><Documents/></Protected>} />
            <Route index path='documents/:folderId' element={<Protected><Documents/></Protected>} />
            <Route path='sides' element={<Protected><Sides /></Protected>}/>
            <Route path='settings' element={<Protected><Settings/></Protected>} />
            <Route path='logs' element={<Protected><Logs /></Protected>}/>
            <Route path='nda' element={<Protected><NDASection /></Protected>}/>
            <Route path='chat'>
              <Route index element={<Protected><Chat /></Protected>} />
              <Route path='document/:chatId' element={<Protected><Chat /></Protected>} />
              <Route path='user/:chatId' element={<Protected><Chat /></Protected>} />
            </Route>
          </Route>
        </Route>

        <Route path='*' element={<WrongAddress />}/>
      </Routes>
    </CacheBuster>
  );
}

const Protected = ({ children }: any) => {
  let location = useLocation();
  const accessToken = getCookie('athena-token');

  if (!accessToken) {
    return <Navigate to='/login' state={{ from: location }} />
  }

  return children;
}

const Authorization = ({ children }: any) => {
  if (getCookie('athena-token')) {
    return <Navigate to='/'/>
  }
  return children;
}
