import { requestTemplate } from 'api';
import { IChat, IChatPayload } from 'interfaces';

type HistoryResponseType = {
  created_at: string;
  id: string;
  content: string;
  chat_id: string;
  author: {
    email: string;
    name: string;
    id: string;
  };
};

const createChat = (payload: IChatPayload) => requestTemplate.post<IChat>('/chats/', payload);

const getRoomChats = (room_id: string) => requestTemplate.get<IChat[]>(`/chats/?room_id=${room_id}`);

const getChatInfo = (chat_id: string) => requestTemplate.get<IChat>(`/chats/${chat_id}/`);

const getChatHistory = (chat_id: string) =>
  requestTemplate.get<HistoryResponseType[]>(`/chats/message/?chat_id=${chat_id}`);

const chats = {
  createChat,
  getRoomChats,
  getChatInfo,
  getChatHistory,
};

export default chats;
