import { Tabs, TabsProps, Typography } from 'antd';
import { CreateChatModal } from 'components/Modals';
import MessangerLoader from 'components/Sceletons/MessangerLoader';
import { membersType } from 'interfaces';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { createChat, fetchChatHistory } from 'store/reducers/chatCreator';
import { setIsOpenCreateModal, setSelectedChatRoom } from 'store/slices/chatsSlice';
import Messanger from './Messanger';
import classes from './Messanger.module.scss';

type Props = {
  onSendMessage: (event: any) => void;
};

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => {
  return <DefaultTabBar {...props} style={{display: 'none'}} />;
};

export default function MessengerWithTabs({ onSendMessage }: Props) {
  const [listStatus, setListStatus] = useState<'createList' | 'groupList'>('createList');
  const { roomAllChats, chatData, currnetChatLoading, selectedChat, isOpenCreateModal } = useAppSelector((state) => state.chat);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userData } = useAppSelector((state) => state.userData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onCancelCreateModal = () => {
    setListStatus('createList');
    dispatch(setIsOpenCreateModal(false));
  };
  const onChatUsersOpen = () => {
    setListStatus('groupList');
    dispatch(setIsOpenCreateModal(true));
  };

  const onClickItem = async (member: membersType) => {
    onCancelCreateModal();
    const { payload } = (await dispatch(
      createChat({
        data: {
          members_ids: [userData?.id!, member.id],
          entity_type: 2,
          room_id: dataRoom?.id!,
          title: member.email,
        },
        ownerId: userData?.id!,
      })
    )) as any;
    const pathTypes = [`document/${payload.chat.id}`, ``, `user/${payload.chat.id}`];
    navigate(`/room/${dataRoom?.custom_url || dataRoom?.id}/chat/${pathTypes[payload.chat.entity_type]}`);
  };

  const items = roomAllChats.map((chat) => ({
    key: chat.id,
    label: <div className={classes.messanger_tab_item}>{chat.title}</div>,
    children:
      currnetChatLoading && selectedChat?.id === chat.id ? (
        <MessangerLoader />
      ) : (
        <Messanger
          t={t}
          onChatUsersOpen={onChatUsersOpen}
          onSendMessage={onSendMessage}
          chatGroups={chatData[chat.id].grouped}
          chatInfo={selectedChat}
        />
      ),
  }));

  const listCases = {
    createList: useMemo(
      () =>
        dataRoom?.available_users?.map(({ last_name, first_name, id, email }) => ({
          name: `${first_name} ${last_name}`,
          email,
          id,
        })),
      [dataRoom]
    ),
    groupList: selectedChat?.members.map((member) => member),
  };

  return (
    <>
      <Tabs
        popupClassName={classes.destroy}
        className={classes.messanger_tabs}
        items={items}
        tabPosition='left'
        renderTabBar={renderTabBar}
        activeKey={selectedChat?.id}
        moreIcon={null}
      />

      {isOpenCreateModal && (
        <Suspense fallback={<div />}>
          <CreateChatModal
            t={t}
            members={listCases[listStatus]!}
            ownerId={userData?.id!}
            onClickItem={onClickItem}
            open={isOpenCreateModal}
            onCancel={onCancelCreateModal}
            title={t('Chats.modals.create.title')}
          />
        </Suspense>
      )}
    </>
  );
}
