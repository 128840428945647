import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox, Form, Input, List, Modal } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { TFunction } from 'i18next';
import { membersType } from 'interfaces';
import { CSSProperties, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { createChat } from 'store/reducers/chatCreator';
import classes from './CreateChatModal.module.scss';

type Props = {
  title: string;
  open: boolean;
  ownerId: string;
  members: membersType[];
  onCancel: () => void;
  onClickItem: (member: membersType) => void;
  t: TFunction;
};

export default function CreateChatModal({ title, open, onCancel, members, onClickItem, ownerId, t }: Props) {
  const [modalStatus, setModalStatus] = useState<'privateCreate' | 'groupCreate'>('privateCreate');
  const [checkedMembers, setCheckedMembers] = useState<CheckboxValueType[]>([]);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onGroupChatCreate = () => setModalStatus('groupCreate');
  const onGroupChatCancel = () => {
    setModalStatus('privateCreate');
    setCheckedMembers([]);
  };
  const onCheckBoxChange = (checkedValues: CheckboxValueType[]) => setCheckedMembers(checkedValues);
  const onCreateUserChat = async (values: { title: string }) => {
    onCancel();
    const chatInfo = await dispatch(
      createChat({
        data: {
          entity_type: 2,
          room_id: dataRoom?.id!,
          members_ids: [ownerId, ...(checkedMembers as string[])],
          title: values.title || '',
        },
        ownerId: ownerId,
      })
    );
    navigate(`/room/${dataRoom?.custom_url || dataRoom?.id}/chat/user/${(chatInfo.payload as any).chat.id}`)
    
  };

  const disabledStyles: CSSProperties = {
    cursor: 'not-allowed',
    backgroundColor: '#fff',
  };

  const modalCases = {
    privateCreate: (
      <List
        dataSource={members}
        className={classes.list}
        renderItem={(item, index) => (
          <List.Item
            style={{ cursor: ownerId !== item.id ? 'pointer' : 'default' }}
            className={classes.list_item}
            onClick={() => ownerId !== item.id && onClickItem(item)}
          >
            <List.Item.Meta
              title={
                <div>
                  {item.name}
                </div>
              }
              description={item.email}
              avatar={<Avatar className={classes.avatar} icon={<UserOutlined />} />}
              className={classes.list_meta}
            />
          </List.Item>
        )}
      />
    ),
    groupCreate: (
      <Checkbox.Group
        className={classes.list_checkGroup}
        value={checkedMembers}
        style={{ display: 'block' }}
        onChange={onCheckBoxChange}
      >
        <List
          dataSource={members}
          className={classes.list}
          renderItem={(item, index) => (
            <Checkbox className={classes.list_checkbox} value={item.id} disabled={item.id === ownerId}>
              <List.Item style={ownerId === item.id ? disabledStyles : undefined} className={classes.list_item}>
                <List.Item.Meta
                  title={
                    <div>
                      {item.name}
                    </div>
                  }
                  description={item.email}
                  avatar={<Avatar className={classes.avatar} icon={<UserOutlined />} />}
                  className={classes.list_meta}
                />
              </List.Item>
            </Checkbox>
          )}
        />
      </Checkbox.Group>
    ),
  };

  const modalActions = {
    privateCreate: (
      <>
        <Button onClick={onGroupChatCreate}>{t("Chats.modals.create.createUser")}</Button>
        <Button disabled>Создать чат по Документам</Button>
      </>
    ),

    groupCreate: (
      <>
        <Button htmlType='submit' disabled={!checkedMembers.length}>
          {t("Chats.modals.create.button")}
        </Button>
        <Button onClick={onGroupChatCancel}>{t("main.cancelButton")}</Button>
      </>
    ),
  };

  return (
    <Modal title={title} open={open} onCancel={onCancel} footer={null} centered className={classes.modal}>
      <Form onFinish={onCreateUserChat}>
        <div className={classes.modal_body}>
          {checkedMembers.length > 1 && (
            <Form.Item name='title'>
              <Input placeholder='Type title of new chat' />
            </Form.Item>
          )}
          {modalCases[modalStatus]}

          <div className={classes.modal_actions}>{modalActions[modalStatus]}</div>
        </div>
      </Form>
    </Modal>
  );
}
