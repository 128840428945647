import { CheckOutlined, CloseOutlined, QuestionCircleOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm, Select, Typography, message, Tooltip} from 'antd';
import api from 'api';
import { IUsers } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { defaultGroups, usersTableWithNewUsersRole } from 'store/reducers/usersGroupCreator';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { setUserAndData, setUserChoosen } from 'store/slices/dataUsersSlice';
import { setMoreInfoHidden } from 'store/slices/windowStateSlice';
import classes from '../UsersTab.module.scss';
import './styles.scss'
const { Item, useForm } = Form;

const PersonInformation = () => {
  const [isDeletingUser, setIsDeletingUser] = React.useState<boolean>(false);
  const [choosenGroup, setChoosenGroup] = React.useState<string | null>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isLoadingEdits, setIsLoadingEdits] = React.useState(false);
  const [form] = useForm();

  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const { users, groups, dataUserTable, selectedUser, rolesPermissions } = useAppSelector((state) => state.dataUsers);
  const { userData, userPermissions } = useAppSelector((state) => state.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  
  const isItYou = selectedUser?.id === userData?.id;
  const isAvailableToExcludeUser = userPermissions?.can_exclude_users && selectedUser?.id && !selectedUser?.isOwner && !isItYou;
  const isAvailableEditUserGroup = userPermissions?.can_edit_user_group;
  const isItRoomOwner = selectedUser?.id === dataRoom?.owner_id;
  
  React.useEffect(() => {
    selectedUser && setFieldsValue();
    setIsEditing(false);
  }, [selectedUser]);

  const setFieldsValue = () => {
    setChoosenGroup(selectedUser?.group[0] || null)
    form.setFieldsValue({
      first_name: selectedUser?.first_name || '',
      email: selectedUser?.email,
      last_name: selectedUser?.last_name,
      phone_number: selectedUser?.phone_number,
      group_id: selectedUser?.group[0],
    });
  };

  const onEditClick = () => setIsEditing(true);

  const onCancelClick = () => {
    setIsEditing(false);
    selectedUser && setFieldsValue();
  };  

  const onSubmitEdit = async (values: any) => {
    setIsLoadingEdits(true);
    const newUsers = users.map((user) =>
      user.email === selectedUser?.email ? { ...user, group: [values.group_id] } : user
    );
    const newSelectedUser = { ...selectedUser!, group: [values.group_id] };
    const targetGroupName = groupsSelectOptions.find(el => el.value === choosenGroup)?.label

    try {
      await api.addUserToGroup({
        email: selectedUser?.email!,
        group_id: values.group_id,
        room_id: dataRoom?.id!,
        details: {
          username: selectedUser?.first_name,
          group_name: groups.find(group => group.id === values.group_id)?.name,
          prev_group_name: groups.find(group => group.id === selectedUser?.group[0])?.name
        }
      });

      dispatch(setUserAndData({ users: newUsers, dataUserTable: usersTableWithNewUsersRole(dataUserTable, users, selectedUser!, values.group_id, t) }));
      dispatch(setUserChoosen(newSelectedUser));
      message.success(`${t('Users.success.addUser')} '${targetGroupName}'`);
    } catch (err) {
      message.error(`${t('Users.error.addUser')} '${targetGroupName}'`);      
      form.setFieldValue('group_id', selectedUser?.group[0])
    } finally {
      setIsLoadingEdits(false);
    }
  };

  const deleteSelectedUser = async () => {
    setIsDeletingUser(true);
    try {
      await api.deleteSelectedUser(selectedUser!.id, dataRoom!.id);

      const tableDataWithoutSelectedUser = dataUserTable.map(elem => {
        let first_name: string;
        const isNeedToChangeName = elem.children?.find((child: IUsers) => child.id === selectedUser!.id);        
        if (isNeedToChangeName) first_name = `${elem.emailOrRole} (${elem.children?.length ? (elem.children.length - 1) : 0})`;
        else first_name = elem.first_name!;
        return {
          ...elem,
          first_name,
          children: elem.children ? elem.children.filter(user => user.id !== selectedUser!.id) : [],
        }
      });
      const available_users = dataRoom?.available_users!.filter(user => user.id !== selectedUser?.id) || [];      

      dispatch(setMoreInfoHidden(true));
      dispatch(setUserChoosen(null));
      dispatch(setUserAndData({
        dataUserTable: tableDataWithoutSelectedUser,
        users: users.filter(elem => elem.id !== selectedUser!.id),
      }));
      dispatch(setDataRoom({ ...dataRoom!, available_users }));
      message.success(t('Users.confirm.userExcludeSuccess'));
    } catch (e) {
      message.error(t('Users.confirm.userExcludeError'));
    } finally {
      setIsDeletingUser(false);
    }
  }

  const groupsSelectOptions = groups.map((group) => ({
    label: defaultGroups.includes(group.name)
    ? t(`Users.table.${group.name}`)
    : group.name,
    value: group.id,
    key: group.key,
  }));
  
  const groupPermissions = groups.find(group => group.id === selectedUser?.group[0])?.role?.permissions;

  const statusIcons = {
    true: <CheckOutlined style={{color: 'green', paddingRight: 10, fontSize: 16}}/>,
    false: <CloseOutlined style={{color: 'red', paddingRight: 10, fontSize: 16}}/>
  };

  return (
    <Form form={form} onFinish={onSubmitEdit} layout='vertical' className='personal_info'>
      {!selectedUser ? (
        <Typography.Title style={{ margin: 0 }} level={4}>
          {t('personalInfo.nonSelected')}
        </Typography.Title>
      ) : (
        <>
          <div className={classes.item}>
            <div className={classes.itemTitle}>{t('personalInfo.firstname')}</div>
            <div className={classes.itemValue}>{selectedUser?.first_name}</div>
          </div>

          <div className={classes.item}>
            <div className={classes.itemTitle}>{t('personalInfo.lastname')}</div>
            <div className={classes.itemValue}>{selectedUser?.last_name}</div>
          </div>

          <div className={classes.item}>
            <div className={classes.itemTitle}>{t('personalInfo.phone')}</div>
            <div className={classes.itemValue}>{selectedUser?.phone_number}</div>
          </div>

          <div className={classes.item}>
            <div className={classes.itemTitle}>{t('personalInfo.email')}</div>
            <div className={classes.itemValue}>{selectedUser?.email}</div>
          </div>

          <div className={classes.item}>
            <div className={classes.itemTitle}>{t('personalInfo.permissions')}</div>
            <div className={classes.itemValue}>{
              rolesPermissions?.map(permission => (<div key={permission.id}>
                <Tooltip title={permission.description}>
                  {statusIcons[String(groupPermissions?.includes(permission.type)) as keyof typeof statusIcons]}{permission.name}
                </Tooltip>
              </div>))
            }</div>

          </div>

          <Item className={classes.itemTitle} label={t('personalInfo.group')} name='group_id'>
            <Select
              placeholder={t('personalInfo.none')}
              disabled={!isEditing || isLoadingEdits || isItRoomOwner}
              options={groupsSelectOptions}
              value={choosenGroup}
              onChange={setChoosenGroup}
            />
          </Item>

          <div className='form_buttons'>
            {isEditing ? (
              <>
                <Button onClick={onCancelClick}>{t('Users.HelperMenu.cancel')}</Button>
                <Button type='primary' htmlType='submit' disabled={choosenGroup === selectedUser?.group[0]} loading={isLoadingEdits}>
                  {t('Users.HelperMenu.save')}
                </Button>
              </>
            ) : <>
              {isAvailableEditUserGroup && 
                (<Button type='primary' onClick={onEditClick}>
                  {t('Users.HelperMenu.edit')}
                </Button>)
              }
              {userPermissions?.can_exclude_users && 
                <Popconfirm
                  title={t('Users.confirm.excludeTitle')}
                  description={
                    `${t('Users.confirm.confirmUserExclude')}
                    ${selectedUser?.first_name} ${selectedUser?.last_name} (${selectedUser?.email})
                    ${t('Users.confirm.fromRoom')}`
                  }
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  cancelText={t('Users.confirm.cancel')}
                  okText={t('Users.confirm.yes')}
                  onConfirm={deleteSelectedUser}
                >
                  {/* <Tooltip title={t('Users.managment.exclude')}> */}
                    <Button
                      disabled={!isAvailableToExcludeUser}
                      type='primary'
                      style={{marginLeft: 10}}
                      loading={isDeletingUser}
                      icon={<UserDeleteOutlined />}
                    >
                      {t('Users.managment.excludeBtn')}
                    </Button>
                  {/* </Tooltip> */}
                </Popconfirm>
              }
            </>}
          </div>
        </>
      )}
    </Form>
  );
};

export default PersonInformation;
