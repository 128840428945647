import { Tooltip, Badge } from 'antd';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { IChat } from 'interfaces';
import { useEffect, useRef } from 'react';
import classes from './ChatTab.module.scss';

type Props = {
  selectedChat: IChat;
  searchValue: string;
  chat: IChat;
  onTabClick: () => void;
  markText: (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number) => void;
  t: TFunction;
};

function ChatTabItem({ searchValue, chat, selectedChat, onTabClick, markText, t }: Props) {
  const markerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (markText) {
      const text = searchValue || '';
      markText(text, markerRef);
    }
  }, [searchValue]);

  return (
    <div
      className={classNames(classes.chatTab_item, selectedChat?.id === chat.id && classes.active)}
      onClick={onTabClick}
      key={chat.id}
      ref={markerRef}
    >
      <Tooltip
        placement='topLeft'
        mouseEnterDelay={1}
        title={`${t(`Chats.types.${chat.entity_type}`)} ${chat.entity_name || chat.title} ${
          chat.entity_type === 2 && chat.title
        }`}
      >
        <div className={classes.chatTab_title}>
          <div className={classNames(classes.chatTab_title_inner, classes.chatTab_title_description)}>
            {t(`Chats.types.${chat.entity_type}`)}
          </div>
          <div style={{ fontWeight: 600 }} className={classes.chatTab_title_inner}>
            {chat.entity_name || chat.title}{' '}
            {chat.entity_type === 2 && <span className={classes.chatTab_title_description}>{chat.title}</span>}
          </div>
        </div>
      </Tooltip>

      <div className={classes.chatTab_item_actions}>
        <div></div>
        {chat.unviewed_amount ? <Badge count={chat.unviewed_amount} /> : null}
      </div>
    </div>
  );
}

export default ChatTabItem;
