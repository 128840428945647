import { configureStore } from '@reduxjs/toolkit';
import windowStateReducer from "./slices/windowStateSlice";
import userDataReducer from "./slices/userDataSlice";
import dataRoomReducer from "./slices/dataRoomSlice";
import dataDocumentsReducer from "./slices/dataDocumentsSlice";
import dataUsersReducer from "./slices/dataUsersSlice";
import viewerReducer from "./slices/viewerSlice";
import profileReducer from "./slices/profileSlice";
import dataLogsReducer from "./slices/dataLogsSlice";
import notesReducer from "./slices/dataNotes";
import permissionsReducer from './slices/dataPermissionsSlice';
import sidesReducer from './slices/dataSidesSlice';
import organizationReducer from './slices/organization';
import agreementsReducer from "./slices/dataAgreementsSlice";
import chatReducer from './slices/chatsSlice';

const store = configureStore({
  reducer: {
    windowState: windowStateReducer,
    userData: userDataReducer,
    dataRoom: dataRoomReducer,
    documents: dataDocumentsReducer,
    dataUsers: dataUsersReducer,
    viewer: viewerReducer,
    profile: profileReducer,
    logs: dataLogsReducer,
    notes: notesReducer,
    permissions: permissionsReducer,
    sides: sidesReducer,
    organization: organizationReducer,
    agreements: agreementsReducer,
    chat: chatReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
