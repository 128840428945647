import React from 'react';
import classes from './UsersTab.module.scss';
import { IUsers } from 'interfaces';
import { Button, Popconfirm, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hook';
import { defaultGroups } from 'store/reducers/usersGroupCreator';
import classNames from 'classnames';
import { QuestionCircleOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';

type UserCardProps = {
  user: IUsers;
  changeRole: (user: IUsers, group_id: string, targetGroupName?: string) => Promise<void>;
  deleteUser: (selectedUser: IUsers) => Promise<void>;
  markText: (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number) => void;
  searchValue: string;
  onUserClick: (event: any, userId: string) => void;
};

const UserCard = ({user, changeRole, deleteUser, onUserClick, markText, searchValue}: UserCardProps) => {
  const markerTitle = React.useRef<HTMLDivElement>(null);
  const markerDesc = React.useRef<HTMLDivElement>(null);
  const [openPopconfirm, setOpenPopconfirm] = React.useState<boolean>(false);
  const [isDeletingUser, setIsDeletingUser] = React.useState<boolean>(false);
  const [installingNewRole, setInstallingNewRole] = React.useState(false);

  const { userPermissions } = useAppSelector((state) => state.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { groups } = useAppSelector((state) => state.dataUsers);

  const { t } = useTranslation();
  const isRoomOwnerCard = user?.id === dataRoom?.owner_id;

  React.useEffect(() => {
    if (markText) {
      const text = searchValue || '';      
      markText(text, markerTitle);
      markText(text, markerDesc);
    };
  }, [searchValue]);

  const fullName = `${user.first_name} ${user.last_name}`;
  
  const groupsSelectOptions = groups.map((group) => ({
    label: defaultGroups.includes(group.name)
    ? t(`Users.table.${group.name}`)
    : group.name,
    value: group.id,
    key: group.key,
  }));  

  const isAvailableEditUserGroup = userPermissions?.can_edit_user_group;
  const targetGroupName = groupsSelectOptions.find(el => el.value === user?.group[0])?.label;

  const handleChangeGroup = async (value: any) => {
    setInstallingNewRole(true);
    try {
      await changeRole(user, value, targetGroupName)
    } catch (err) { } finally {
      setInstallingNewRole(false);
    }
  };

  const deleteSelectedUser = async (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    setIsDeletingUser(true);
    setOpenPopconfirm(false);
    e?.stopPropagation()
    try {
      await deleteUser(user);
    } catch (e) { } finally {
      setIsDeletingUser(false);
    }
  };

  const handleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpenPopconfirm(true);
  }

  const hidePopconfirm = () => {
    setOpenPopconfirm(false);
  }

  return (
    <div
      className={classNames(
        classes.userCard,
        installingNewRole ? classes.loading : null
      )}
      onClick={event => onUserClick(event, user.id)}
    >
      <div className={classes.jusctifySpace}>
        <div ref={markerTitle}  className={classes.userTitle}>
          {isRoomOwnerCard && (
            <Tooltip title={t('Users.table.owner')}>
              <FontAwesomeIcon style={{paddingRight: 5}} color={'gold'} icon={faCrown} />
            </Tooltip>
          )}
          <Tooltip title={fullName} mouseEnterDelay={1}>
            {fullName}  
          </Tooltip>
        </div>

        {userPermissions?.can_exclude_users && !isRoomOwnerCard &&
          <Popconfirm
            title={t('Users.confirm.excludeTitle')}
            onPopupClick={e => e.stopPropagation()}
            open={openPopconfirm}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            cancelText={t('Users.confirm.cancel')}
            onCancel={hidePopconfirm}
            okText={t('Users.confirm.yes')}
            onConfirm={deleteSelectedUser}
            zIndex={1100}
            description={
              `${t('Users.confirm.confirmUserExclude')}
              ${fullName} (${user?.email})
              ${t('Users.confirm.fromRoom')}`
            }
          > 
            <Tooltip
              title={t('Users.managment.exclude')}
              zIndex={1000}
              mouseEnterDelay={0.4}
            >
              <Button
                loading={isDeletingUser}
                onClick={handleOpen}
                size='small'
                type='text'
                icon={<UserDeleteOutlined className={classes.deleteIcon}/>}
              />
            </Tooltip>
          </Popconfirm>
        }
      </div>
      <div className={classes.jusctifySpace}>
        <div ref={markerDesc} className={classes.userDescription}>
          <Tooltip title={user.email} mouseEnterDelay={1}>
            {user.email}  
          </Tooltip>
        </div>

        {isAvailableEditUserGroup && !isRoomOwnerCard
          ? (
            <Select
              placeholder={t('personalInfo.none')}
              size='small'
              loading={installingNewRole}
              disabled={user?.id === dataRoom?.owner_id || installingNewRole}
              options={groupsSelectOptions}
              value={user?.group[0]}
              popupMatchSelectWidth={false}
              onClick={(e) => e.stopPropagation()}
              onChange={handleChangeGroup}
            />
          )
          : <div className={classes.userDescription}>{targetGroupName}</div>
        }
      </div>
    </div>
  );
}

export default UserCard;
