import { TFunction } from "i18next";
import classes from '../DocumentTable.module.scss';
import { IDocument } from "interfaces";

export const getDocumentColumns = (t: TFunction) => [
  {
    title: t('Documents.table.columns.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a: IDocument, b: IDocument) => a.name.localeCompare(b.name),
    // ellipsis: true,
    width: '42%',
  },
  {
    title: t('Documents.table.columns.date'),
    dataIndex: 'upload_at',
    key: 'upload_at',
    sorter: (a: IDocument, b: IDocument) => a.upload_at - b.upload_at,
    ellipsis: true,
    width: '13%',
  },
  {
    title: t('Documents.table.columns.agreement'),
    dataIndex: 'agreement',
    key: 'agreement',
    width: '10%',
    ellipsis: true,
    className: classes.textToCenter,
  },
  {
    title: t('Documents.table.columns.notes'),
    dataIndex: 'notes',
    key: 'notes',
    width: '10%',
    ellipsis: true,
    className: classes.textToCenter,
  },
  {
    title: t('Documents.table.columns.chat'),
    dataIndex: 'chats',
    key: 'chats',
    width: '10%',
    ellipsis: true,
    className: classes.textToCenter,
  },
  {
    title: t('Documents.management.permissions'),
    dataIndex: 'permissions',
    key: 'permissions',
    width: '10%',
    ellipsis: true,
    className: classes.textToCenter,
  },
  {
    title: '',
    dataIndex: 'details',
    key: 'details',
    width: '5%',
    className: classes.textToCenter,
  },
];
