import RowLoader from 'components/Sceletons/RowLoader';
import Mark from 'mark.js';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchChatHistory } from 'store/reducers/chatCreator';
import { setIsOpenCreateModal, setSelectedChatRoom } from 'store/slices/chatsSlice';
import classes from './ChatTab.module.scss';
import ChatTabControls from './ChatTabControls';
import ChatTabItem from './ChatTabItem';

function ChatTab() {
  const [searchValue, setSearchValue] = useState<string>('');
  const { roomAllChats, chatData, selectedChat, isLoadedSession, roomChatLoading } = useAppSelector(
    (state) => state.chat
  );
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoadingChat = !Boolean(dataRoom?.id && isLoadedSession && !roomChatLoading);
  const onCreateModalOpen = () => dispatch(setIsOpenCreateModal(true));

  const filteredChats = useMemo(() => {
    return roomAllChats.filter((chat) =>
      `${t(`Chats.types.${chat.entity_type}`)} ${chat.entity_name || chat.title} ${
        chat.entity_type === 2 && chat.title
      }`
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
  }, [searchValue, roomAllChats]);

  const markText = (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number) => {
    const markInstance = new Mark(markerRef.current as HTMLElement);

    setTimeout(() => {
      markInstance.unmark({
        className: classes.mark,
        done: () => {
          markInstance.mark(text, {
            className: classes.mark,
          });
        },
      });
    }, timeout || 100);
  };

  return (  
    <div className={classes.chatTab}>
      <ChatTabControls
        t={t}
        searchValue={searchValue}
        isLaoding={isLoadingChat}
        setSearchValue={setSearchValue}
        onCreateModalOpen={onCreateModalOpen}
      />

      {isLoadingChat ? (
          <div style={{marginRight: 10}}><RowLoader width={600} padding={'0 0 0 10px'} height={70} borderradius={5} /></div>
        ) : (
        <div className={classes.chatTab_tabs}>
          {filteredChats.map((chat) => {
            const onTabClick = async () => {
              const pathTypes = [`document/${chat?.id}`, ``, `user/${chat?.id}`];
              dispatch(setSelectedChatRoom(chat));
              if (!chatData[chat.id].isLoadedHistory)
                await dispatch(fetchChatHistory({ chat_id: chat.id, room_id: chat?.room_id! }));
              navigate(`/room/${dataRoom?.custom_url || dataRoom?.id}/chat/${pathTypes[chat!.entity_type]}`);
            };

            return (
              <ChatTabItem
                t={t}
                key={chat.id}
                onTabClick={onTabClick}
                markText={markText}
                searchValue={searchValue}
                selectedChat={selectedChat!}
                chat={chat}
              />
            );
          })}
        </div>
      )}

    </div>
  );
}

export default ChatTab;
