import { IColumn } from 'interfaces';
import printJS from 'print-js';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import '../assets/fonts/GolosText-VariableFont_wght-normal';
import { getMockActions } from './getMockActions';
import i18next, { t } from 'i18next';

const prepareData = <T>(data: T[], columns: IColumn[]) => {

  const mockActions = getMockActions(i18next.getFixedT('ru'), t);

  const result = data?.map((el: any) => {
    const arrWithValues: string[] = [];
    for (const column of columns) {
      if(column.key === 'entityname' && el[column.dataIndex] === 'отсутствует'){
        const actionIdx = mockActions.ru.findIndex((action) => el[column.dataIndex] === action);
        arrWithValues.push(mockActions.actual[actionIdx]);
      } else {
        arrWithValues.push(el[column.dataIndex]);
      }
    }
    return arrWithValues;
  });
  return result;
};

export const pdfHelper = async <T>(data: any, columnsState: any, nameOfDocument: string, actionType?: string) => {

  const dataForPrint = prepareData<T>(data, columnsState);

  const pdf = new JsPDF('l', 'pt', 'a4');
  pdf.addFont('GolosText-VariableFont_wght-normal.ttf', 'Golos', 'normal');
  pdf.setFont('Golos');
  pdf.text(nameOfDocument, 50, 30);

  autoTable(pdf, {
    columns: columnsState.map((el: any) => el.title),
    body: dataForPrint,
    styles: { font: 'Golos', fontStyle: 'normal', minCellWidth: 75},
    headStyles: { halign: 'center' },
    bodyStyles: { lineWidth: 0.1, lineColor: [73, 138, 159] }, // + halign: 'center',
    columnStyles: {
      // 0: { fontSize: 16, halign: 'center' },
      // 2: { cellWidth: 150 },
      // 3: { cellWidth: 150 },
      // 6: { cellWidth: 50 }

    },
  });

  const nameForExport = nameOfDocument.split('.');
  nameForExport.pop();

  actionType === 'export'
    ? pdf.save(`${nameForExport}.pdf`)
    : printJS({
      printable: pdf.output('bloburl'),
      type: 'pdf',
    });
};
