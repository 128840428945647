import React, { ReactNode } from 'react'
import classes from '../FileDetailsModal.module.scss';
import { Button, message, Switch, Tooltip, TreeSelect } from 'antd';
import { TFunction } from 'i18next';
import { IDocument, IFolder, IGroup, ISimpleGroupForAprove, ISimpleUserForAprove, IUsers, } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchAgreements } from 'store/reducers/agreementsCreator';
import { setDocuments, setSelectedDocument, setSelectedFolder } from 'store/slices/dataDocumentsSlice';
import { resetAgreementState } from 'store/slices/dataAgreementsSlice';
import { defaultGroups } from 'store/reducers/usersGroupCreator';
import api from 'api';

type AgreementCardProps = {
  selectedDocument: IDocument | null;
  selectedFolder: IFolder | null;
  documentPermissions: string[];
  t: TFunction;
};

const tooltipElement = (title:string, content: string) => <Tooltip title={title}>{content}</Tooltip>;

export default function AgreementCard({ selectedDocument, documentPermissions, selectedFolder, t }: AgreementCardProps) {
  const [usersId, setUsersId] = React.useState<string[]>([]);
  const [isEnable, setIsEnable] = React.useState(false);
  const [dataTreeSelect, setDataTreeSelect] = React.useState<any[]>([]);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isAddingArgeement, setIsAddingAgreement] = React.useState(false);

  const { users, groups, isGroupLoading } = useAppSelector((store) => store.dataUsers);
  const { agreements, isArgeementsLoading, needAgreementFromUsers } = useAppSelector((store) => store.agreements);
  const { documents, folders } = useAppSelector((store) => store.documents);  

  const reduxUsersId = needAgreementFromUsers.map(user => user.user_id);
  const availableToSendAgreements = reduxUsersId.sort().join(',') === usersId.sort().join(',');

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setIsEnable(Boolean(agreements[0]));
    users[0] && setUsersId(needAgreementFromUsers.map(user => user.user_id));
  },[agreements, users]);

  React.useEffect(() => {
    const treeData = groups.map(group => prepareGroups(group, users, t));
    setDataTreeSelect(treeData);
  },[users]);

  const onChangeAgreementStatus = async () => {
    if (isEnable && agreements[0]) {
      setIsDeleting(true);
      try {
        await api.deleteAgreement({
          id: String(selectedFolder ? selectedFolder.id : selectedDocument?.id),
          entity: selectedFolder ? 'folder' : 'document',
        });

        message.success(t('Documents.details.agreement.successDisabledAgreed'));

        dispatch(resetAgreementState());

        selectedFolder
          ? dispatch(setDocuments({documents, folders: folders.map(folder => {
            return folder.id === selectedFolder.id
              ? { ...folder, agreement_status: null }
              : folder
          })}))
          : dispatch(setDocuments({
            documents: documents.map(document => {
              return document.id === selectedDocument!.id
              ? { ...document, agreement_status: null }
              : document
            }),
            folders
          }))
      } catch (e) {
        message.error(t('Documents.details.agreement.errorDisableAgreed'));
      } finally {
        setIsDeleting(false);

        selectedDocument 
          ? dispatch(fetchAgreements({id: selectedDocument?.id!, entity: 'document'}))
          : dispatch(fetchAgreements({id: selectedFolder?.id!, entity: 'folder'}));
      }
    } else {
      setIsEnable(!isEnable);
    }
  }
  
  const onChange = (newValue: string[], label: ReactNode[], extra: any) => {
    setUsersId(newValue);
  };

  const sendRequestForApproval = async () => {
    const choosenUsers = users.filter(user => usersId.includes(user.id)).map(user => {
      const targetUserState = needAgreementFromUsers.find(someUser => someUser.user_id === user.id)?.agreement_state;
      return {
      user_id: user.id,
      user_name: user.first_name,
      user_email: user.email,
      agreement_state: targetUserState || null,
      parent_group_id: user.group[0]
    }});
    
    const simpleGroups: ISimpleGroupForAprove[] = groups.map(group => ({group_id: group.id, group_name: group.name}));
    
    const jsonForRequest = choosenUsers.reduce((acc: ISimpleGroupForAprove[], currentUser: ISimpleUserForAprove) => {
      const targetGroup = simpleGroups.find(group => group.group_id === currentUser.parent_group_id);
      const theSameGroupInAcc = acc.find((group => group.group_id === targetGroup?.group_id));
      
      delete currentUser.parent_group_id;
      
      theSameGroupInAcc
        ? acc = acc.map((group: any) => (group.group_id === targetGroup?.group_id ? {...group, users: [...group.users, currentUser]} : group))
        : acc.push({...targetGroup!, users: [currentUser]})
      
      return acc;
    }, []);

    setIsAddingAgreement(true);

    try {
      await api.addAgreement({
        id: String(selectedFolder ? selectedFolder.id : selectedDocument?.id),
        entity: selectedFolder ? 'folder' : 'document',
        agreement_state: jsonForRequest
      });

      if(selectedFolder){
        dispatch(setDocuments({documents, folders: folders.map(folder => {
          return folder.id === selectedFolder.id
            ? { ...folder, agreement_status: 0 }
            : folder
        })}))
        dispatch(setSelectedFolder({...selectedFolder, agreement_status: 0}));
      } else {
        dispatch(setDocuments(
          {
            documents: documents.map(document => {
              return document.id === selectedDocument!.id
              ? { ...document, agreement_status: 0, permissions: documentPermissions }
              : document
            }),
            folders
          }
        ))
        dispatch(setSelectedDocument({...selectedDocument!, agreement_status: 0}));
      }

      message.success(t('Documents.details.agreement.sendForAgreement'));
    } catch (e) {
      message.error(t('Documents.details.agreement.sendForAgreementErr'));
    } finally {
      setIsAddingAgreement(false);
      selectedDocument 
        ? dispatch(fetchAgreements({id: selectedDocument?.id!, entity: 'document'}))
        : dispatch(fetchAgreements({id: selectedFolder?.id!, entity: 'folder'}));
    }
  }

  const isDisabledForAproove = React.useMemo(() => {
    const agreementStates = agreements?.reduce((acc: ISimpleUserForAprove[], currentGroup: ISimpleGroupForAprove) => {
      if (currentGroup.users) {
        const usersWithGroupAndFullName = currentGroup.users.filter(user => user.agreement_state !== null);
        acc = [...acc, ...usersWithGroupAndFullName]
      }
      return acc
    }, []) 

    return Boolean(agreementStates.length);

  }, [agreements])
  
  const tProps = {
    treeData: dataTreeSelect,
    value: usersId,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_CHILD,
    placeholder: t('Documents.details.agreement.selectUsers'),
    style: { width: isEnable ? '80%' : '100%' },
  };  

  return (
    <div className={classes.agreement_card}>

      <div className={classes.agreement_switchWrap}>
        <Switch
          loading={isArgeementsLoading || isDeleting}
          size='small'
          checked={isEnable}
          onChange={onChangeAgreementStatus}
          disabled={isDisabledForAproove}
        />
        <span className={classes.agreement_titleSpan}>{t('Documents.details.agreement.text')}</span>
      </div>

      <div className={classes.agreement_selectWrap}>
        <TreeSelect
          disabled={!isEnable || isGroupLoading || isAddingArgeement || Boolean(agreements[0])}
          loading={isGroupLoading || isArgeementsLoading}
          placement='topLeft'
          {...tProps}
        />
        {
          isEnable &&
          <Button
            className={classes.agreement_sendButton}
            loading={isAddingArgeement}
            disabled={availableToSendAgreements || isGroupLoading || isAddingArgeement}
            onClick={sendRequestForApproval}
          >
            {t('Documents.details.agreement.send')}
          </Button>
        }
      </div>

    </div>
  )
};

const prepareGroups = (group: IGroup, users: IUsers[], t: TFunction) => {
  const usersOfThisGroup = users.filter((user) => user.group.includes(group?.id));
  const canEditAgreement = group.role?.permissions.includes('can_edit_agreement');
  const isDisabledRole = !Boolean(usersOfThisGroup[0]) || !canEditAgreement;
  const localizedGroupName = defaultGroups.includes(group.name) ? t(`Users.table.${group.name}`) : group.name;

  const tooltipRoleText = !canEditAgreement
    ? t('Documents.details.agreement.noRolePermission')
    : !usersOfThisGroup[0] 
      ? t('Documents.details.agreement.emptyRole')
      : '';

  return {
    title: isDisabledRole ? tooltipElement(tooltipRoleText, localizedGroupName) : localizedGroupName,
    value: group.id,
    key: group.id,
    disableCheckbox: isDisabledRole,
    children: usersOfThisGroup.map(user => ({
      disableCheckbox: !canEditAgreement,
      title: !canEditAgreement
        ? tooltipElement(
          t('Documents.details.agreement.noAgreementPermission'),
          `${user.first_name} ${user.last_name}`
        )
        : `${user.first_name} ${user.last_name}`,
      value: user.id,
      key: user.id,
    })),
  };
};
